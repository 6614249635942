import { CurrencyDollarIcon } from "@heroicons/react/24/outline";

const DashboardNetWorthSummary = () => {
  const assets = [
    {
      name: "Property",
      value: "$880,000",
    },
    {
      name: "Savings",
      value: "$24,864",
    },
    {
      name: "All Others",
      value: "$16,000",
    },
  ];

  const liabilities = [
    {
      name: "Mortgage",
      value: "$800,000",
    },
    {
      name: "Car Loan",
      value: "$12,800",
    },
    {
      name: "All Others",
      value: "$36,167",
    },
  ];

  return (
    <div className="col-span-1 sm:col-span-1 lg:col-span-1 bg-white px-4 pb-2 pt-5 shadow sm:px-6 sm:pt-6 rounded-lg shadow">
      <div className="font-semibold text-gray-900 text-lg">
        Net Worth Summary
      </div>
      <div className="flex justify-between items-center flex-row font-semibold text-sm text-gray-700">
        <div className="flex">Assets</div>
        <div className="flex items-end">
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            $920,864
          </span>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {assets.map((item, i) => {
          if (i < 3) {
            return (
              <li className="relative py-1">
                <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                  <div className="flex flex-row items-center gap-x-2">
                    <CurrencyDollarIcon className="h-4 w-4 flex-none rounded-full bg-green-100 text-green-300" />
                    <p className="text-xs font-semibold leading-6 text-gray-700">
                      {item.name}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-xs leading-6 text-gray-900">
                        {item.value}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
      <div className="mt-2 flex justify-between items-center flex-row font-semibold text-sm text-gray-700">
        <div className="flex">Liabilities</div>
        <div className="flex items-end">
          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
            $848,967
          </span>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {liabilities.map((item, i) => {
          if (i < 3) {
            return (
              <li className="relative py-1">
                <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                  <div className="flex flex-row items-center gap-x-2">
                    <CurrencyDollarIcon className="h-4 w-4 flex-none rounded-full bg-red-100 text-red-300" />
                    <p className="text-xs font-semibold leading-6 text-gray-700">
                      {item.name}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-xs leading-6 text-gray-900">
                        {item.value}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
      <div className="mt-2 flex justify-between items-center flex-row font-semibold text-sm text-gray-700">
        <div className="flex">Net Worth</div>
        <div className="flex items-end">
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            $71,897
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardNetWorthSummary;
