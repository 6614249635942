import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  HandThumbUpIcon,
  HomeIcon,
  LinkIcon,
  MapIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultProfilePic from "../../assets/img/default-profile-pic.jpg";
import FoleoFavicon from "../../assets/img/foleo_logo_black_b.png";
import { useAuth } from "../../utils/auth/auth";
import { classNames } from "../../utils/helpers/misc";

export default function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: location.pathname.includes("/dashboard"),
      external: false,
    },
    {
      name: "Net Worth",
      href: "/net-worth",
      icon: CurrencyDollarIcon,
      current: location.pathname.includes("/net-worth"),
      external: false,
    },
    {
      name: "Cash Flow",
      href: "/cash-flow",
      icon: CreditCardIcon,
      current: location.pathname.includes("/cash-flow"),
      external: false,
    },
    {
      name: "Connections",
      href: "/settings/integrations",
      icon: LinkIcon,
      current: location.pathname.includes("/settings/integrations"),
      external: false,
    },
    {
      name: "Settings",
      href: "/settings",
      icon: CogIcon,
      current: location.pathname.includes("/settings"),
      external: false,
    },
    {
      name: "Divider",
      href: "#",
      icon: LinkIcon,
      current: location.pathname.includes("#"),
      external: false,
    },
    {
      name: "Roadmap",
      href: "https://foleo.featurebase.app/",
      icon: MapIcon,
      current: false,
      external: true,
    },
    {
      name: "Feedback",
      href: "https://tally.so",
      icon: HandThumbUpIcon,
      current: false,
      external: true,
    },
    {
      name: "Help Center",
      href: "https://tawk.to/",
      icon: QuestionMarkCircleIcon,
      current: false,
      external: true,
    },
  ];

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-indigo-800 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 py-4 overflow-y-auto">
                  <div
                    onClick={() => navigate("/user-management")}
                    className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
                  >
                    <img
                      className="h-8 w-auto"
                      src={FoleoFavicon}
                      alt="Workflow"
                    />
                    <span className="text-white text-xl font-bold ml-2">
                      Foleo
                    </span>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => {
                      if (item.external) {
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            className={classNames(
                              "text-gray-300 hover:bg-indigo-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                "text-gray-400 group-hover:text-gray-300",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        );
                      } else if (item.name === "Divider") {
                        return (
                          <hr className="h-px my-12 px-2 py-2 bg-gray-100 border-0 dark:bg-gray-700 w-full" />
                        );
                      } else {
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-indigo-900 text-white"
                                : "text-gray-300 hover:bg-indigo-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        );
                      }
                    })}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex bg-indigo-700 p-4">
                  <div className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={DefaultProfilePic}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {auth.session?.user.email ?? "User"}
                        </div>
                        <div
                          onClick={() => navigate("/signout")}
                          className="text-sm font-medium text-indigo-400 group-hover:text-indigo-500 cursor-pointer w-fit"
                        >
                          Sign out
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-black">
          <div className="flex-1 flex flex-col py-4 overflow-y-auto">
            <div
              onClick={() => navigate("/user-management")}
              className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
            >
              <img className="h-8 w-auto" src={FoleoFavicon} alt="Workflow" />
              <span className="text-white text-xl font-bold ml-2">Foleo</span>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-2">
              {navigation.map((item) => {
                if (item.external) {
                  return (
                    <a href={item.href} key={item.name}>
                      <div
                        className={classNames(
                          "text-amber-100 hover:bg-gray-800 hover:text-amber-100",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            "text-amber-100 group-hover:text-amber-100",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    </a>
                  );
                } else if (item.name === "Divider") {
                  return (
                    <hr
                      className="h-px mx-2 bg-gray-300 opacity-50 border-0 w-full"
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    />
                  );
                } else {
                  return (
                    <Link to={item.href} key={item.name}>
                      <div
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-200 hover:bg-gray-800 hover:text-white",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-white"
                              : "text-gray-200 group-hover:text-white",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    </Link>
                  );
                }
              })}
            </nav>
          </div>
          <div className="flex-shrink-0 flex bg-gray-800 p-4">
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={DefaultProfilePic}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-white">
                    {auth.session?.user.email ?? "User"}
                  </div>
                  <div
                    onClick={() => navigate("/signout")}
                    className="text-xs font-medium text-amber-400 group-hover:text-amber-500 cursor-pointer w-fit"
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-indigo-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
