import { Link, useLocation } from "react-router-dom";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AccountSettings from "../../components/Settings/AccountSettings/AccountSettings";
import Sidebar from "../../components/Sidebar/Sidebar";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();

  const secondaryNavigation = [
    {
      name: "Account",
      to: "/settings/account",
      current: location.pathname.includes("/settings/account"),
    },
    {
      name: "Billing",
      to: "/settings/billing",
      current: location.pathname.includes("/settings/billing"),
    },
    {
      name: "Integrations",
      to: "/settings/integrations",
      current: location.pathname.includes("/settings/integrations"),
    },
    {
      name: "Notifications",
      to: "/settings/notifications",
      current: location.pathname.includes("/settings/notifications"),
    },
  ];

  return (
    <>
      <Sidebar />
      <main className="md:pl-64 flex flex-col flex-1">
        <header className="border-b border-white/5">
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto py-4">
            <ul
              role="list"
              className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-900 sm:px-6 lg:px-8"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.to}
                    className={item.current ? "text-indigo-500" : ""}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path={"/account"} element={<AccountSettings />} />
          <Route path="/" element={<Navigate to="/settings/account" />} />
        </Routes>
      </main>
    </>
  );
}
