import DashboardCashFlowSummary from "../../components/DashboardCashFlowSummary/DashboardCashFlowSummary";
import DashboardKeyMetrics from "../../components/DashboardKeyMetrics/DashboardKeyMetrics";
import DashboardNetWorthGraph from "../../components/DashboardNetWorthGraph/DashboardNetWorthGraph";
import DashboardNetWorthSummary from "../../components/DashboardNetWorthSummary/DashboardNetWorthSummary";
import Sidebar from "../../components/Sidebar/Sidebar";

export const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                Your overview
              </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="my-2">
                <DashboardKeyMetrics />
              </div>
              <div className="my-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                <DashboardNetWorthGraph />
                <DashboardCashFlowSummary />
                <DashboardNetWorthSummary />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
