import { faker } from "@faker-js/faker";
import { Line } from "react-chartjs-2";

const DashboardNetWorthGraph = () => {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Net Worth",
        data: labels.map(() => faker.number.int({ min: 20000, max: 100000 })),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="col-span-1 sm:col-span-2 lg:col-span-2 bg-white p-4 rounded-lg shadow">
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top" as const,
            },
          },
          backgroundColor: "#fff",
        }}
        data={data}
        updateMode="resize"
      />
    </div>
  );
};

export default DashboardNetWorthGraph;
