import { AuthError, Session, User } from "@supabase/supabase-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../../pages/Loading/Loading";
import api from "../helpers/api";
import { supabase } from "../supabase";

interface AuthContextType {
  session: Session | null;
  loading: boolean;
  loadingInitial: boolean;
  signUpWithEmail: (
    email: string,
    password: string
  ) => Promise<User | AuthError | null>;
  signInWithEmail: (
    email: string,
    password: string
  ) => Promise<AuthError | void>;
  signInWithAuthProvider: (
    authProvider: "twitter" | "google" | "facebook"
  ) => void;
  signout: (callback?: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = supabase.auth;
  const [session, setSession] = useState<any>(
    JSON.parse(localStorage.getItem("foleoSession") ?? "{}") ?? {}
  );
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChange((event, session) => {
      setLoading(true);
      setSession(session);
      setLoadingInitial(false);
      localStorage.setItem("foleoSession", JSON.stringify(session));
      setLoading(false);
    });
  }, []);

  const signUpWithEmail = async (
    email: string,
    password: string
  ): Promise<User | null | AuthError> => {
    setLoading(true);
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: process.env.REACT_APP_URL + "/dashboard",
      },
    });

    setLoading(false);

    if (error) {
      return error;
    } else {
      return data.user;
    }
  };

  const signInWithEmail = async (
    email: string,
    password: string
  ): Promise<void | AuthError> => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      return error;
    }

    if (data) {
      setSession(data.session);
      localStorage.setItem("foleoSession", JSON.stringify(data.session));
    }
    setLoading(false);
  };

  const signInWithAuthProvider = async (
    authProvider: "twitter" | "google" | "facebook"
  ) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: authProvider,
    });

    if (error) {
      return error;
    }
  };

  const signout = async (callback?: VoidFunction) => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error(error);
    }

    setSession(null);
    localStorage.removeItem("foleoSession");

    if (callback) {
      callback();
    }
  };

  const memoedValue = useMemo(
    () => ({
      session,
      loading,
      loadingInitial,
      signUpWithEmail,
      signInWithEmail,
      signInWithAuthProvider,
      signout,
    }),
    [session, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {loadingInitial ? <Loading /> : children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.session) {
    return (
      <Navigate to="/signin" replace state={{ from: location.pathname }} />
    );
  } else if (auth.session.user.email && !auth.session.user.confirmed_at) {
    return (
      <Navigate
        to={`/verify-email?email=${auth.session.user.email}`}
        replace
        state={{ from: location.pathname }}
      />
    );
  }

  return children;
}

export function RequireSubscription({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const [returnComponent, setReturnComponent] = useState<JSX.Element>(children);

  useEffect(() => {
    if (auth.session && !auth.loading) {
      api.updateClient(auth.session.access_token);
      api
        .getCustomerSubscriptions()
        .then((subscriptions) => {
          if (subscriptions.data.subscriptions.length > 0) {
            for (const subscription of subscriptions.data.subscriptions) {
              if (
                subscription.status === "active" ||
                subscription.status === "trialing"
              ) {
                setReturnComponent(children);
                return;
              }
              setReturnComponent(<Navigate to="/choose-subscription" />);
            }
          } else {
            setReturnComponent(<Navigate to="/choose-subscription" />);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [auth.loading]);

  return returnComponent;
}
