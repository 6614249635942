import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  BanknotesIcon,
  ChartBarIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "../../utils/helpers/misc";

export default function DashboardKeyMetrics() {
  const [currentTab, setCurrentTab] = useState("Last 30 days");

  const stats = [
    {
      id: 1,
      name: "Net Worth",
      stat: "$71,897",
      icon: ChartBarIcon,
      change: "1.2%",
      changeType: "increase",
      changeDirectionInverse: false,
    },
    {
      id: 2,
      name: "Savings",
      stat: "$24,864",
      icon: BanknotesIcon,
      change: "5.4%",
      changeType: "increase",
      changeDirectionInverse: false,
    },
    {
      id: 3,
      name: "Income",
      stat: "$5,961",
      icon: CurrencyDollarIcon,
      change: "3.2%",
      changeType: "decrease",
      changeDirectionInverse: false,
    },
    {
      id: 4,
      name: "Spending",
      stat: "$2,981",
      icon: CreditCardIcon,
      change: "3.2%",
      changeType: "decrease",
      changeDirectionInverse: true,
    },
  ];

  const tabs = [
    {
      name: "Last 7 days",
      onClick: () => setCurrentTab("Last 7 days"),
      current: currentTab === "Last 7 days",
    },
    {
      name: "Last 30 days",
      onClick: () => setCurrentTab("Last 30 days"),
      current: currentTab === "Last 30 days",
    },
    {
      name: "Last 3 months",
      onClick: () => setCurrentTab("Last 3 months"),
      current: currentTab === "Last 3 months",
    },
    {
      name: "Last 12 months",
      onClick: () => setCurrentTab("Last 12 months"),
      current: currentTab === "Last 12 months",
    },
    {
      name: "MTD",
      onClick: () => setCurrentTab("MTD"),
      current: currentTab === "MTD",
    },
    {
      name: "QTD",
      onClick: () => setCurrentTab("QTD"),
      current: currentTab === "QTD",
    },
    {
      name: "YTD",
      onClick: () => setCurrentTab("YTD"),
      current: currentTab === "YTD",
    },
    {
      name: "All time",
      onClick: () => setCurrentTab("All time"),
      current: currentTab === "All time",
    },
  ];

  return (
    <div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-amber-600 focus:outline-none focus:ring-amber-600 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current)!.name}
            onChange={(event) => setCurrentTab(event.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={tab.onClick}
                  className={classNames(
                    tab.current
                      ? "border-amber-600 text-amber-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-amber-100 p-3">
                <item.icon
                  className="h-6 w-6 text-gray-900"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">
                {item.stat}
              </p>
              <p
                className={classNames(
                  item.changeType === "increase"
                    ? "text-green-600"
                    : "text-red-600",
                  "ml-2 flex items-baseline text-sm font-semibold"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
